/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Image} from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { todo, overrides, ...rest } = props;
  return (
    <Flex
      gap="0.5em"
      direction="row"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0.8rem 2rem"
      backgroundImage="linear-gradient(90deg, rgb(63, 117, 255), rgb(86, 188, 249) 100%)"
      {...rest}
    >
      <Flex
        gap="1em"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="left"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
      >
        <Image
          alt="Konnected logo"
          src="/konnected-logo-white.svg"
          height="28px"
        ></Image>        
      </Flex>
    </Flex>
  );
}
