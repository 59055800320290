import React from "react";
import logo from './../logo-blue.svg';
import { useEffect, useState } from 'react';
import { get } from 'aws-amplify/api';

import { Button, withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth'

function HomePage({ signOut, user }) {
  const [devices, setDevices] = useState([]);  
  
  useEffect(() => {
    fetchKonnectedDevices();
  }, []);

  async function fetchKonnectedDevices() {
    try {
      const restOperation = get({
        apiName: 'KonnectedCloud.us-east-1',
        path: '/devices',
        options: {
          headers: { Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() }        
        }
      });
      const { body } = await restOperation.response;
      const json_body = await body.json();
      setDevices(json_body.devices.filter(d => d.firmware === 'esphome'));
    } catch (error) {
      console.log('GET call failed: ', error);
    }
  }

  return (
    <div className="App">
        <div style={styles.container}>
          <header className="App-header">
            <img src={logo} className="App-MainLogo" alt="logo" />
          </header>
          <p>Hello {user.username}</p>
          <Button onClick={signOut}>Sign out</Button>
          <h2>My Devices</h2>
          <ol>
            {devices.map((device, index) => (
            <li key={device.id ? device.id : index}>
              <a href={`/devices/` + device.id}>{device.id}</a>
              &nbsp;{device.type} {device.chip}
            </li>
            ))}
          </ol>
        </div>
    </div>
  );
}

export default withAuthenticator(HomePage, {
  usernameAttributes: 'email',
  socialProviders: ['google','apple'],
  loginMechanisms: ['email']
})

const styles = {
  container: {
    width: '60%',
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  button: {
    backgroundColor: "black",
    color: "white",
    outline: "none",
    fontSize: 18,
    padding: "12px 0px",
  }
};
