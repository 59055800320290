import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import RelativeTime from 'react-relative-time'
import { get, post } from 'aws-amplify/api';
import { Card, View, Text, Image, withAuthenticator, Flex, Heading, Button, Loader } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import NavBar from "./../ui-components/NavBar";

function DevicePage({signOut, user}) {
  const [device, setDevice] = useState({});  
  let { id } = useParams();
  
  useEffect(() => {
    async function fetchDevice() {
      try {
        const restOperation = get({
          apiName: 'KonnectedCloud.us-east-1',
          path: '/devices/' + id,
          options: {
            headers: { Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() }        
          }
        });
        const { body } = await restOperation.response;
        const json_body = await body.json();
        console.log(json_body);
        setDevice(json_body.device);
      } catch (error) {
        console.log('GET call failed: ', error);
      }
    }
    fetchDevice();
  }, [id]);

  function DeviceCard({device}) {
    if (device.id) {
      return <Card 
        variation="elevated"
        width="100%"
        shrink="0"          
        >
          <Flex
            direction="row"
            alignItems="flex-start"
            gap="2rem"
          >          
            <ProductImage device={device}/>
            <Flex direction="column">
              <Heading level="3">{device.name || "konnected-" + device.id }</Heading>
              <View>
                Device ID: {device.id}
                <br/>
                <ModelName device={device}></ModelName>
              </View>
              <EsphomeBadge device={device}></EsphomeBadge>
              <BuildStatus build={device.build}></BuildStatus>                                        
          </Flex>
        </Flex>
      </Card>
    } else {
      return <Loader size="large"></Loader>        
    }
  }
  function ProductImage({device}) {
    switch (device.type) {
      case 'GdoV2S':
      case 'GarageDoor':
        return <Image 
          alt="GDO White"
          src="/gdo-angle-render.png"
          objectFit="initial"
        ></Image>
      case 'AlarmPanel':
        if (device.chip === 'ESP32') {
          return <Image 
            alt="Alarm Panel Pro"
            src="/alarm-panel-pro.jpg"
            objectFit="initial"
          ></Image>
        } else {
          return <Image 
            alt="Alarm Panel Pro"
            src="/alarm-panel-and-addon-v2.png"
            objectFit="initial"
          ></Image>
        }
      default:
        return ""
    }
  }

  function EsphomeBadge({device}) {
    if (device.firmware === 'esphome') {
      return <Image margin="1em 0" alt="Made for ESPHome" src="/made-for-esphome-black-on-transparent.svg" width="45%"></Image>
    }
  }

  function ModelName({device}) {
    switch (device.type) {
      case 'GdoV2S':
        return <Text>GDO White (GDOv2-S)</Text>
      case 'AlarmPanel':
        if (device.chip === 'ESP32') {
          return <Text>Alarm Panel Pro (APPROv1)</Text>
        } else {
          return <Text>Alarm Panel</Text>
        }
      default:
        return ''
    }
  }

  function BuildStatus({build}) {
    switch (build?.status) {
      case 'draft':
        return <Text>Draft v{build.version}</Text>
      case 'built':
        if (Date.parse(build.updated_at) > new Date().getTime() - (24 * 60 * 60 * 1000)) {
          return <>
          <esp-web-install-button 
            manifest={build?.manifest_url} 
            new_install_prompt_erase="true"
          >
            <Button 
              variation="primary" 
              slot="activate"
              isFullWidth={true}
            >Install Latest Firmware</Button>
          </esp-web-install-button>
          <Text variation="secondary" textAlign="center" fontSize="0.8rem">
            Build v{build?.version} updated <RelativeTime value={build.updated_at}></RelativeTime></Text>
        </>
        } else {
          return <>
          <Button 
            slot="activate"
            isFullWidth={true}
            onClick={() => renewFirmware(build)}
          >Renew Expired Firmware</Button>
          <Text variation="secondary" textAlign="center" fontSize="0.8rem">
          Build v{build?.version} expired <RelativeTime value={Date.parse(build.updated_at) + (24*60*60*1000)}></RelativeTime></Text>
          </>
        }

      default:
        <Text>No build</Text>
    }
  }

  async function renewFirmware(build) {
    try {
      const restOperation = post({
        apiName: 'KonnectedCloud.us-east-1',
        path: '/devices/' + build.device_id + '/builds/' + build.version + '/renew',
        options: {
          headers: { Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() }        
        }
      });
      const { body } = await restOperation.response;
      const json_body = await body.json();
      console.log(json_body);
      setDevice({...device, build: json_body.build});

    } catch (error) {
      console.log('POST call failed: ', error);
    }
  }

  return (
    <>    
      <Flex direction="column">
        <NavBar  overrides={{
          Content: {
            maxWidth: "1440px",
            margin: "0 auto",
          }
        }}>          
        </NavBar>
        <Flex 
          gap="12px"
          direction="column"
          width="100%"
          maxWidth="660px"
          height="unset"
          margin="0 auto"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="12px 0px 12px 0px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
        >

          <View>
            <DeviceCard device={device}></DeviceCard>
            {(device.id) && false && (
            <Flex direction="row" margin="2rem 0">
              <Heading level="4">Firmware details</Heading>
            </Flex>         
            )}
          </View>
          <Button variation="link" onClick={signOut} fontWeight="normal">Sign out</Button>
        </Flex>
      </Flex>
    </>
  );
}

export default withAuthenticator(DevicePage, {
  usernameAttributes: 'email',
  socialProviders: ['google','apple'],
  loginMechanisms: ['email']
})