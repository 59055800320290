const environment = {
  Cognito: {
    userPoolClientId: "5nkuplfralkjvmf4q9udavmt0c",
    userPoolId: "us-east-1_ECd7GNwxE",
    loginWith: {
      oauth: {
        domain: "auth.konnected.io",
        scopes: ['openid email phone profile aws.cognito.signin.user.admin'],
        redirectSignIn: ['http://localhost:3000','https://app.konnected.io'],
        redirectSignOut: ['http://localhost:3000','https://app.konnected.io'],
        responseType: 'code',
      },
      email: 'true',
      phone: 'true',
      username: 'false'
    }
  },
  REST: {
    "KonnectedCloud.us-east-1": {
      endpoint: "https://cloud.us-east-1.konnected.io",
      region: 'us-east-1'
    },
    "KonnectedCloud.eu-west-1": {
      endpoint: "https://cloud.eu-west-1.konnected.io",
      region: 'eu-west-1'
    },
    "KonnectedCloud.ap-northeast-1": {
      endpoint: "https://cloud.ap-northeast-1.konnected.io",
      region: 'ap-northeast-1'
    }
  }
};

export default environment;