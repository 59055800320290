import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,  
  RouterProvider
} from "react-router-dom";

import './index.css';
import { Amplify } from 'aws-amplify';
import HomePage from "./pages/HomePage";
import DevicePage from "./pages/DevicePage";
import environment from "./config/environment";

import '@aws-amplify/ui-react/styles.css';
// Supports weights 100-900
import '@fontsource-variable/inter';

Amplify.configure({
  Auth: {
    Cognito: environment.Cognito
  },
  API: {
    REST: environment.REST
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "devices/:id",
    element: <DevicePage />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

